(function($) {
  jQuery(document).ready(function() {
    function initialize() {
      var bounds = new google.maps.LatLngBounds();

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            var map = new google.maps.Map(
              document.getElementById("map_canvas"),
              {
                zoom: 11,
                mapTypeId: "roadmap",
                zoomControl: true,
                streetViewControl: false,
                mapTypeControl: false,
                scrollwheel: false,
                center: {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                },
              }
            );
            map.setCenter(pos);
            map.setZoom(11);
            var countries = [];
            var markers = jQuery(".acf-map").find(".marker");
            if (markers.length > 0) {
              jQuery(".map-results-message").html(
                "Results will appear below the map."
              );
            } else {
              jQuery(".map-results-message").html(
                "<span style='color:red;'>Please broaden your search criteria. Either there are no locations in this area, or the Financial Institution you entered is not a client of Everlink’s at this time.</span>"
              );
            }
            markers.each(function() {
              var title = jQuery(this).attr("data-title");
              var address = jQuery(this).html();
              var lat = jQuery(this).attr("data-lat");
              var lon = jQuery(this).attr("data-lon");
              countries.push({
                title: title,
                lat: lat,
                lon: lon,
                address: address,
              });
            });

            var image = {
              url: theme + "/images/marker.png",
              size: new google.maps.Size(32, 32),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(16, 32),
            };
            for (var i = 0; i < countries.length; i++) {
              var country = countries[i];
              var latlng = new google.maps.LatLng(
                parseFloat(country["lat"]),
                parseFloat(country["lon"])
              );
              bounds.extend(latlng);
              var address = country["address"];
              var marker = new google.maps.Marker({
                position: latlng,
                map: map,
                title: country["title"],
                icon: image,
              });
              var infowindow = new google.maps.InfoWindow();
              google.maps.event.addListener(
                marker,
                "click",
                (function(address) {
                  return function() {
                    infowindow.setContent(address);
                    infowindow.open(map, this);
                  };
                })(address)
              );
            }

            if (countries.length > 0) {
              if (
                $("#address").val() != "" ||
                $("#institution-name").val() != ""
              ) {
                map.fitBounds(bounds);
                map.panToBounds(bounds);
              }
            }
          },
          () => {
            handleLocationError(true, infoWindow, map.getCenter());
          }
        );
      }
      console.log("here");
    }
    if (jQuery("#map_canvas").length) {
      // When the window has finished loading create our google map below
      initialize();
      //google.maps.event.addDomListener(window, "load", initialize);
    }

    jQuery("body").on("keyup", "#institution-name", function() {
      jQuery.ajax({
        type: "post",
        dataType: "json",
        url: ajaxurl,
        data: {
          action: "financial_institution",
          keyword: jQuery(this).val(),
        },
        beforeSend: function() {
          jQuery("#institution-name").css(
            "background",
            "#FFF url(" + theme + "/images/loader.gif) no-repeat 165px"
          );
        },
        success: function(response) {
          if (response.data != "") {
            jQuery("#suggesstion-box").show();
            jQuery("#suggesstion-box").html(response.data);
            jQuery("#institution-name").css("background", "#FFF");
          } else {
            jQuery("#suggesstion-box").hide();
            jQuery("#institution-name").removeAttr("style");
          }
        },
      });
    });

    var defaultBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(-33.8902, 151.1759),
      new google.maps.LatLng(-33.8474, 151.2631)
    );

    var input = document.getElementById("address");
    /*var options = {
      bounds: defaultBounds,
      types: ['establishment']
    };*/
    var options = {
      componentRestrictions: { country: "ca" },
    };

    var autocomplete = new google.maps.places.Autocomplete(input, options);

    $("input.deletable")
      .wrap('<span class="deleteicon" />')
      .after(
        $("<span/>").click(function() {
          $(this).prev("input").val("").trigger("change").focus();
          $(this).hide();
        })
      );

    $("#clear-fields-map").click(function() {
      $(".clearable").val("");
      $(".deleteicon").find("span").hide();
    });

    $("input.deletable").on("keyup", function() {
      displayClearButton($(this));
    });

    function displayClearButton(inputfield) {
      if (inputfield.val() != "") {
        inputfield.parent().find("span").show();
      } else {
        inputfield.parent().find("span").hide();
      }
    }

    jQuery("body").on("click", "#institution-list li", function() {
      var val = jQuery(this).attr("data-id");
      jQuery("#institution-name").val(val);
      jQuery("#suggesstion-box").hide();
    });
    if (jQuery(".single-search-box").length) {
      jQuery(".arrow-button").on("click", function() {
        var $button = jQuery(this);
        var oldValue = $button.parent().find("input").val();
        if ($button.text() == "+") {
          var newVal = parseFloat(oldValue) + 1;
        } else {
          // Don't allow decrementing below zero
          if (oldValue > 1) {
            var newVal = parseFloat(oldValue) - 1;
          } else {
            newVal = 1;
          }
        }
        $button.parent().find("input").val(newVal);
      });
    }
  });
})(jQuery);
