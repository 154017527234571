(function($) {
  jQuery(document).ready(function() {
    $(".banner-carousel").slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      autoplay: true,
      autoplaySpeed: 4000,
      prevArrow: $(".banner-prev"),
      nextArrow: $(".banner-next"),
      responsive: [
        {
          breakpoint: 768,
          settings: {
            autoplay: false,
            adaptiveHeight: true,
          },
        },
      ],
    });
  });
})(jQuery);
