(function ($) {
  jQuery(document).ready(function () {
    let x = 0;
    $(".text-cards .text-card .title")
      .each(function (id, it) {
        if ($(it).height() > x) {
          x = $(it).height();
        }
      })
      .height(x);
  });
})(jQuery);
