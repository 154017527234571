(function ($) {
  jQuery(document).ready(function () {
    $(".open_search_box").on("click", function () {
      $(".search_box").addClass("search_box_visible");
      setTimeout(() => {
        $("#s_mobile").focus();
      }, 500);
    });
    $("#close_search_box i").on("click", function () {
      $(".search_box").removeClass("search_box_visible");
    });
  });
})(jQuery);
