(function ($) {
  jQuery(document).ready(function () {
    let x = 0;
    $(".archive-cards .archive-card .title-container")
      .each(function (id, it) {
        if ($(it).height() > x) {
          x = $(it).height();
        }
      })
      .height(x);
  });
})(jQuery);
