(function ($) {
  jQuery(document).ready(function () {
    let x = 0;

    $(".mini-cards-carousel-slick").slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: true,
      autoplay: true,
      autoplaySpeed: 4000,
      // prevArrow: $(".banner-prev"),
      // nextArrow: $(".banner-next"),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 780,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    $(
      ".mini-cards-carousel-section .mini-cards-carousel-contain .mini-card-carousel .title"
    )
      .each(function (id, it) {
        if ($(it).height() > x) {
          x = $(it).height();
        }
      })
      .height(x);
  });
})(jQuery);
