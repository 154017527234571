(function ($) {
  jQuery(document).ready(function () {
    $(".testimonials-slick").slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      autoplay: true,
      autoplaySpeed: 4000,
      // prevArrow: $(".banner-prev"),
      // nextArrow: $(".banner-next"),
      responsive: [
        {
          breakpoint: 480,
          settings: {
            arrows: false,
          },
        },
      ],
    });
  });
})(jQuery);
