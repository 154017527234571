(function($) {
  jQuery(document).ready(function() {
    let x = 0;

    $(".media-events-category-slick").slick({
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      dots: false,
      variableWidth: true,
      /* autoplay: true, */
      /* autoplaySpeed: 4000, */
      // prevArrow: $(".banner-prev"),
      // nextArrow: $(".banner-next"),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 780,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  });
})(jQuery);
