(function ($) {
  jQuery(document).ready(function () {
    $(".line-time-years").slick({
      infinite: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      dots: false,
      autoplay: false,
      // prevArrow: $(".banner-prev"),
      // nextArrow: $(".banner-next"),
      responsive: [
        {
          breakpoint: 780,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    });

    $(".line-time-year").click(function () {
      $(".line-time-year-info span").removeClass("active");
      $(this).find(".line-time-year-info span").addClass("active");
      $(".line-time-year-text-content").css("visibility", "hidden");
      $(".line-time-year-text-content").css("opacity", "0");
      $(".line-time-year-text-content .year").html($(this).attr("data-year"));
      $(".line-time-year-text-content .description").html(
        $(this).attr("data-text")
      );
      $(".line-time-year-text-content").css("visibility", "visible");
      $(".line-time-year-text-content").css("opacity", "1");
    });
  });
})(jQuery);
